import React, { useEffect, useMemo, useState } from "react";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import LockResetIcon from "@mui/icons-material/LockReset";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { useAuth } from "../hooks/auth";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Select } from "@mui/material";
import { useTranslation } from "react-i18next";

function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 1 : 0,
  });
}

export default function Header({ drawerWidth, handleDrawerToggle }) {
  const isDarkTheme = useTheme().palette.mode === "dark";

  const auth = useAuth();
  const user = auth?.user;

  const userName = useMemo(() => user?.name || "", [user]);
  const userProfilePic = useMemo(() => user?.profilePic || "", [user]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState("nl");

  const openProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeProfileMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    auth.logout();
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("selectedLanguage", lng);
    setSelectedLanguage(lng); // Set control value when language changes
  };

  const handleLanguageChange = (event) => {
    const selectedLanguageValue = event.target.value
      ? event.target.value
      : null;
    if (selectedLanguageValue) {
      changeLanguage(selectedLanguageValue);
    }
  };

  const languageList = [
    {
      name: "Dutch",
      value: "nl",
    },
    {
      name: "English",
      value: "en",
    },
  ];

  useEffect(() => {
    let language = localStorage.getItem("selectedLanguage")
      ? localStorage.getItem("selectedLanguage")
      : selectedLanguage;

    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    // eslint-disable-next-line
  }, [i18n]);

  return (
    <ElevationScroll>
      <AppBar
        position="fixed"
        elevation={1}
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          bgcolor: isDarkTheme ? "#161616" : "white",
          // color: isDarkTheme ? "#161616 !important" : "green !important",
        }}
      >
        <Toolbar>
          <IconButton
            color={"primaryText.dark"}
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ mr: 2, display: { md: "none" } }}>
            <img
              src={isDarkTheme ? "/logo_light.svg" : "/logo.svg"}
              alt="Dashbook Logo"
              width={"100px"}
            />
          </Box>
          <Typography
            variant="body1"
            noWrap
            component="div"
            color={"primaryText.light"}
            sx={{ flexGrow: 1 }}
          ></Typography>
          &nbsp;&nbsp;&nbsp;
          <Select
            variant="standard"
            style={{
              border: "none !important",
              color: "white",
            }}
            value={selectedLanguage}
            onChange={(event) => handleLanguageChange(event)}
            displayEmpty
            renderValue={(selected) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={`/${selected}.png`} // Adjust the path as needed
                  alt={selected}
                  style={{ width: 24, marginRight: 8 }}
                />
                {/* {
                    languageList.find((option) => option.value === selected)
                      ?.name
                  } */}
              </div>
            )}
            // MenuProps={{ PaperProps: { sx: { border: "none" } } }} // Remove border from the menu
          >
            {languageList.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <img
                  src={`/${option.value}.png`} // Adjust the path as needed
                  alt={option.value}
                  style={{ width: 24, marginRight: 8 }}
                />
                {option.name}
              </MenuItem>
            ))}
          </Select>
          <Tooltip title="Account settings">
            <IconButton
              onClick={openProfileMenu}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                alt={userName}
                variant="dense"
                src={userProfilePic}
                sx={{
                  bgcolor: isDarkTheme ? "#ecdac6" : "#c69053",
                  width: 32,
                  height: 32,
                }}
              >
                {userName?.charAt(0)}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={closeProfileMenu}
            onClick={closeProfileMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              Profile
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <LockResetIcon />
              </ListItemIcon>
              Change Password
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
}
