import React, { useEffect } from "react";

import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import SideBarItem from "./SideBarItem";
import SideBarNestedItem from "./SideBarNestedItem";
import "./SideBar.css";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CategoryIcon from "@mui/icons-material/Category";
import TagIcon from "@mui/icons-material/Bookmarks";
import AddTaskIcon from "@mui/icons-material/AddTask";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import CampaignIcon from "@mui/icons-material/Campaign";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AssistantIcon from "@mui/icons-material/Assistant";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import PagesIcon from "@mui/icons-material/Pages";
import NewspaperIcon from "@mui/icons-material/Newspaper";

const SideBarContent = ({ isDarkTheme }) => {
  /**
   * label: String
   * route: String - route to redirect
   * activeOn: Array[String] - array of routes in which module will be displayed as activated
   */
  // const [moduleList] = useState([]);

  const { t, i18n } = useTranslation();

  const moduleList = [
    {
      label: t("users.title"),
      route: "/users",
      activeOn: ["/users", "/users/add", "/users/edit/.*"],
      icon: <GroupAddIcon sx={{ color: "white" }} />,
      children: [],
    },
    {
      label: t("ledger_category.title"),
      route: null,
      activeOn: [],

      children: [
        {
          label: t("categories.title"),
          route: "/categories",
          icon: <AcUnitIcon sx={{ color: "white" }} />,
          activeOn: ["/categories", "/categories/add", "/categories/edit/.*"],
        },
        {
          label: t("subcategory.title"),
          route: "/subcategory",
          icon: <CategoryIcon sx={{ color: "white" }} />,
          activeOn: [
            "/subcategory",
            "/subcategory/add",
            "/subcategory/edit/.*",
          ],
        },
        {
          label: t("categorytags.title"),
          route: "/categorytags",
          icon: <TagIcon sx={{ color: "white" }} />,
          activeOn: [
            "/categorytags",
            "/categorytags/add",
            "/categorytags/edit/.*",
          ],
        },
      ],
    },
    {
      label: t("membership.title"),
      route: "/membership",
      activeOn: ["/membership", "/membership/add", "/membership/edit/.*"],
      children: [],
      icon: <AddTaskIcon sx={{ color: "white" }} />,
    },
    {
      label: t("subscription.title"),
      route: "/subscription",
      activeOn: ["/subscription", "/subscription/add", "/subscription/edit/.*"],
      children: [],
      icon: <SubscriptionsIcon sx={{ color: "white" }} />,
    },

    // {
    //   label: t("faq.title"),
    //   route: "/FAQ",
    //   activeOn: ["/FAQ", "/FAQ/add", "/FAQ/edit/.*"],
    // },
    {
      label: t("vat_categories.title"),
      route: "/vat_categories",
      activeOn: [
        "/vat_categories",
        "/vat_categories/add",
        "/vat_categories/edit/.*",
      ],
      children: [],
      icon: <PercentOutlinedIcon sx={{ color: "white" }} />,
    },
    {
      label: t("faq.title"),
      route: "/FAQ",
      activeOn: ["/FAQ", "/FAQ/add", "/FAQ/edit/.*"],
      children: [],
      icon: <HelpOutlineOutlinedIcon sx={{ color: "white" }} />,
    },
    {
      label: t("notification.title"),
      route: "/notification",
      activeOn: ["/notification", "/notification/add", "/notification/edit/.*"],
      children: [],
      icon: <NotificationsIcon sx={{ color: "white" }} />,
    },
    {
      label: t("apps_and_deals.title"),
      route: "/apps_and_deals/apps",
      activeOn: [
        "/apps_and_deals/apps",
        "/apps_and_deals/deals",
        "/apps_and_deals/my_apps",
        "/apps_and_deals/my_deals",
      ],
      children: [],
      icon: <CampaignIcon sx={{ color: "white" }} />,
    },
    {
      label: t("offers.title"),
      route: "/offers",
      activeOn: ["/offers", "/offer/*"],
      children: [],
      icon: <AssistantIcon sx={{ color: "white" }} />,
    },
    {
      label: t("credits.title"),
      route: "/credits",
      activeOn: ["/credits", "/credits/*"],
      children: [],
      icon: <CreditScoreIcon sx={{ color: "white" }} />,
    },
    {
      label: "Blogs",
      route: "/blogs",
      activeOn: ["/blogs", "/blogs/*"],
      children: [],
      icon: <PagesIcon sx={{ color: "white" }} />,
    },
    {
      label: "Newsletter",
      route: "/newslettersList",
      activeOn: ["/newsletter"],
      children: [],
      icon: <NewspaperIcon sx={{ color: "white" }} />,
    },
  ];

  useEffect(() => {
    let language = localStorage.getItem("selectedLanguage")
      ? localStorage.getItem("selectedLanguage")
      : "nl";

    i18n.changeLanguage(language);
    // setSelectedLanguage(language);
    // eslint-disable-next-line
  }, [i18n]);

  return (
    <Paper
      sx={{ height: "100%", bgcolor: isDarkTheme ? "#161616" : "#0d0d0d" }}
      square
    >
      <Toolbar>
        <Box
          sx={{
            mx: "auto",
            my: 2,
          }}
        >
          <img src={"/logo_light.svg"} alt="Dashbook Logo" width={"100px"} />
        </Box>
      </Toolbar>
      <Divider sx={{ borderColor: "#1d1d1d !important" }} />
      <List
        component="nav"
        sx={{ mx: 1 }}
        className={`sidebar ${isDarkTheme ? "dark" : "light"}`}
      >
        {moduleList.map((item, index) => {
          if (item.children.length) {
            return <SideBarNestedItem item={item} key={index} />;
          }

          return <SideBarItem item={item} key={index} />;
        })}
      </List>
    </Paper>
  );
};

export default function SideBar({
  drawerWidth,
  handleDrawerToggle,
  mobileOpen,
  isDarkTheme,
  window,
}) {
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{
        width: { md: drawerWidth },
        flexShrink: { md: 0 },
        height: "100%",
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { sm: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            height: "100%",
            borderRight: "none",
            backgroundColor: isDarkTheme ? "#161616" : "#0d0d0d",
          },
        }}
      >
        <SideBarContent isDarkTheme={isDarkTheme} />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            height: "100%",
            borderRight: "none",
            backgroundColor: isDarkTheme ? "#161616" : "#0d0d0d",
          },
        }}
        open
      >
        <SideBarContent isDarkTheme={isDarkTheme} />
      </Drawer>
    </Box>
  );
}
