import { useAuth } from '../hooks/auth'
import { Navigate, useLocation } from 'react-router-dom';

export const PublicRoute = ({ children }) => {
  const auth = useAuth();
  const location = useLocation();
  const publicRoutes = ["/login", "/forgot-password", "/reset-password"];

  if(publicRoutes.includes(location.pathname) && auth?.user) {
    return <Navigate to="/" />
  }

  return children;
}
