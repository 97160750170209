import { Controller } from "react-hook-form";
import { TextField, Typography } from "@mui/material";

const TextInput = ({
  name,
  control,
  label,
  rules = { required: false },
  customError = null,
  ...otherProps
}) => {
  return (
    <>
      <Typography sx={{ mb: 1 }} variant="body2">
        {label}
      </Typography>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <TextField
              id={name}
              variant="outlined"
              color="info"
              fullWidth
              size="small"
              value={value}
              onChange={onChange}
              error={!!error || !!customError}
              helperText={error ? error.message : customError || null}
              {...otherProps}
            />
          );
        }}
      />
    </>
  );
};

export default TextInput;
