import axios from "axios";

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL });

let userData;

try {
  userData = JSON.parse(localStorage.getItem("dashbook_admin"));
} catch (error) {
  console.warn("something went wrong while parsing user data");
}

let token;

if (userData && userData.tokens && userData.tokens.access.token) {
  token = userData.tokens.access.token;
}

api.defaults.baseURL = process.env.REACT_APP_API_URL;

if (token) {
  api.defaults.headers.common = {
    Authorization: "Bearer " + token,
  };
}

const shouldIntercept = (error) => {
  try {
    return error.response.status === 401;
  } catch (e) {
    return false;
  }
};

function removeUserData() {
  localStorage.removeItem("dashbook_admin");
  window.location.pathname = "/";
}

const setTokenData = (tokenData = {}) => {
  let user = localStorage.getItem("dashbook_admin")
    ? JSON.parse(localStorage.getItem("dashbook_admin"))
    : {};
  user.tokens.access.token = tokenData.idToken;
  user.tokens.refresh.token = tokenData.refreshToken;
  user.tokens.access.expires = tokenData.idTokenExpires;
  user.tokens.refresh.expires = tokenData.refreshTokenExpires;
  localStorage.setItem("dashbook_admin", JSON.stringify(user));
};

const handleTokenRefresh = () => {
  let user = localStorage.getItem("dashbook_admin")
    ? JSON.parse(localStorage.getItem("dashbook_admin"))
    : false;
  const refreshToken = user ? user.tokens.refresh.token : "";

  return new Promise((resolve, reject) => {
    api
      .post("/auth/admin/refresh-tokens", {
        refreshToken: refreshToken,
      })
      .then((response) => {
        const tokenData = {
          idToken: response.data.access.token,
          refreshToken: response.data.refresh.token,
          idTokenExpires: response.data.access.expires,
          refreshTokenExpires: response.data.refresh.expires,
        };
        resolve(tokenData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const attachTokenToRequest = (request, token) => {
  request.headers["Authorization"] = "Bearer " + token;
  api.defaults.headers.common = {
    Authorization: "Bearer " + token,
    Accept: "application/x-www-form-urlencoded",
  };
};

let customAxiosInterceptor = (axiosClient, customOptions = {}) => {
  let isRefreshing = false;
  let failedQueue = [];

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    setTokenData,
    shouldIntercept,
    ...customOptions,
  };
  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  const interceptor = (error) => {
    if (
      error &&
      error.config &&
      error.config.url === "/auth/admin/refresh-tokens"
    ) {
      removeUserData();
    }

    if (!options.shouldIntercept(error)) {
      return Promise.reject(error);
    }

    if (error.config._retry || error.config._queued) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest._queued = true;
          options.attachTokenToRequest(originalRequest, token);
          return axiosClient.request(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;
    return new Promise((resolve, reject) => {
      options.handleTokenRefresh
        .call(options.handleTokenRefresh)
        .then((tokenData) => {
          options.setTokenData(tokenData, axiosClient);
          options.attachTokenToRequest(originalRequest, tokenData.idToken);
          processQueue(null, tokenData.idToken);
          resolve(axiosClient.request(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  };

  axiosClient.interceptors.response.use(undefined, interceptor);
};

customAxiosInterceptor(api);

export default api;
