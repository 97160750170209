function findKeyWithMessage(errorObj, parentKey = "") {
  if (typeof errorObj === "object" && errorObj !== null) {
    for (const key in errorObj) {
      if (Object.prototype.hasOwnProperty.call(errorObj, key)) {
        const currentKey = parentKey ? `${parentKey}.${key}` : key;

        if (typeof errorObj[key] === "object" && errorObj[key] !== null) {
          if ("message" in errorObj[key]) {
            return currentKey;
          }
          const result = findKeyWithMessage(errorObj[key], currentKey);
          if (result) {
            return result;
          }
        } else if (Array.isArray(errorObj[key])) {
          for (let i = 0; i < errorObj[key].length; i++) {
            const result = findKeyWithMessage(
              errorObj[key][i],
              `${currentKey}[${i}]`
            );
            if (result) {
              return result;
            }
          }
        }
      }
    }
  }
  return null;
}

function highlightField(fieldId) {
  try {
    const fieldElem = document.getElementById(fieldId);

    let headerOffset = 100;
    let elementPosition = fieldElem.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  } catch (error) {
    console.warn("Field cannot be highlighted");
  }
}

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }

  const lastSpaceIndex = text.lastIndexOf(" ", maxLength);
  const truncatedText =
    lastSpaceIndex > 0
      ? text.substring(0, lastSpaceIndex) + "..."
      : text.substring(0, maxLength) + "...";

  return truncatedText;
};

function decodeHTMLEntities(input) {
  return input.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
}

function convertObjectToFormData(dataObject) {
  const formData = new FormData();

  // Iterate over the object keys
  for (const key in dataObject) {
    // Check if the property belongs to the object and not its prototype chain
    if (dataObject.hasOwnProperty(key)) {
      // Append each key-value pair to the FormData object
      formData.append(key, dataObject[key]);
    }
  }
  return formData;
}
export {
  findKeyWithMessage,
  highlightField,
  truncateText,
  decodeHTMLEntities,
  convertObjectToFormData,
};
