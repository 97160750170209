import { Controller } from "react-hook-form";
import { Autocomplete, Typography, TextField } from "@mui/material";

const AutocompleteInput = ({
  name,
  control,
  label,
  rules = { required: false },
  customError = null,
  renderInputProps = {},
  onChangeCallback = () => {},
  ...otherProps
}) => {
  return (
    <>
      <Typography sx={{ mb: 1 }} variant="body2">
        {label}
      </Typography>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <Autocomplete
              id={name}
              fullWidth
              size="small"
              value={value}
              onChange={(_, value, reason) => {
                onChange(value); 
                onChangeCallback(_, value, reason);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...renderInputProps}
                  variant="outlined"
                  color="info"
                  error={!!error || !!customError}
                  helperText={error ? error.message : customError || null}
                />
              )}
              {...otherProps}
            />
          );
        }}
      />
    </>
  );
};

export default AutocompleteInput;
