export const getLatitudeLongitude = (place, inputName,setValue) => {
  // test 
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: place }, (results, status) => {
      if (status === "OK" && results.length > 0) {
        const latitude = results[0].geometry.location.lat();
        const longitude = results[0].geometry.location.lng();
        setValue(inputName , { latitude, longitude });
      } else {
        console.log(
          "error",
          "Geocode was not successful for the following reason: " + status
        );
      }
    });
  };