import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  IconButton,
} from "@mui/material";

// import DeleteRounded from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Tooltip from "@mui/material/Tooltip";
import API from "../../config/axios";
import { useNavigate } from "react-router-dom";

function OfferList() {
  const navigate = useNavigate();

  const [allApprovedOffer, setAllApprovedOffer] = useState([]);

  const offerController = useRef(new AbortController());

  const getAllOffer = async () => {
    offerController.current.abort();
    offerController.current = new AbortController();

    API.get("/admin/offer/list", {
      signal: offerController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          const filteredData = resData.data
            .filter((offer) => offer.status !== "unverified")
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setAllApprovedOffer(filteredData);
        } else {
          toast.error("Offers cannot be fetched");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      });
  };

  // Actions
  // Edit
  const editOffer = (offerId) => {
    // Navigate To Path
    navigate(`/offer/${offerId}`);
  };

  const offerListTableRows = allApprovedOffer?.map((offer, offerIndex) => (
    <TableRow key={"offer" + offerIndex}>
      <TableCell>{offerIndex + 1}</TableCell>
      <TableCell>{offer.offerType}</TableCell>
      <TableCell>{offer.company.name}</TableCell>
      <TableCell>
        {offer.explanation ? offer.explanation : "Explanation Not Found"}
      </TableCell>
      <TableCell>{offer.deadline}</TableCell>
      <TableCell>
        {offer.status === "approved" ? (
          <span className="offerApproved">Approved</span>
        ) : offer.status === "pending" ? (
          <span className="offerPending">Pending</span>
        ) : (
          <span className="offerRejected">Rejected</span>
        )}
      </TableCell>
      <TableCell style={{ textAlign: "center" }}>
        <Tooltip title="Wijzigen" arrow>
          <IconButton
            size="small"
            aria-label="edit"
            onClick={() => editOffer(offer._id)}
          >
            <EditOutlinedIcon className="edit_icon" fontSize="medium" />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  ));

  useEffect(() => {
    getAllOffer();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{ mb: 2 }}
        justifyContent="flex-start"
        alignItems={{ xs: "start", sm: "center" }}
        spacing={1}
      ></Stack>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>Offer Type</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Explanation</TableCell>
              <TableCell>Deadline</TableCell>
              <TableCell>Status</TableCell>
              <TableCell style={{ textAlign: "center" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          {allApprovedOffer.length > 0 ? (
            <TableBody>{offerListTableRows}</TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No data available
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}

export default OfferList;
