import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { RequireAuth } from "../utils/RequireAuth";
import { PublicRoute } from "../utils/PublicRoute";
import { NotFound } from "../pages/NotFound";
import MainLayout from "../layouts/MainLayout";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import LoadingPage from "../components/LoadingPage";
import OfferList from "../pages/offers/OfferList";
import EditOffer from "../pages/offers/EditOffer";

// User
const UserList = lazy(() => import("../pages/users/UserList"));
const AddUser = lazy(() => import("../pages/users/AddUser"));
const EditUser = lazy(() => import("../pages/users/EditUser"));
const SubCategoryList = lazy(() =>
  import("../pages/subcategory/SubCategoryList"),
);
const AddSubCategory = lazy(() =>
  import("../pages/subcategory/AddSubCategory"),
);
const EditSubCategory = lazy(() =>
  import("../pages/subcategory/EditSubCategory"),
);

// Category
const CategoryList = lazy(() => import("../pages/categories/CategoryList"));
const AddCategory = lazy(() => import("../pages/categories/AddCategory"));
const EditCategory = lazy(() => import("../pages/categories/EditCategory"));

// VAT Category
const VatCategoryList = lazy(() =>
  import("../pages/vat_categories/VatCategoryList"),
);
const AddVatCategory = lazy(() =>
  import("../pages/vat_categories/AddVatCategory"),
);
const EditVatCategory = lazy(() =>
  import("../pages/vat_categories/EditVatCategory"),
);

//Member Ship
const MemberShipList = lazy(() => import("../pages/membership/MemberShipList"));
const AddMemberShip = lazy(() => import("../pages/membership/AddMemberShip"));
const EditMemberShip = lazy(() => import("../pages/membership/EditMemberShip"));

// Subscription Module
const SubScriptionList = lazy(() =>
  import("../pages/subscription/SubScriptionList"),
);
const AddSubScription = lazy(() =>
  import("../pages/subscription/AddSubScription"),
);
const EditSubscription = lazy(() =>
  import("../pages/subscription/EditSubScription"),
);

// Category
const CategoryTagList = lazy(() =>
  import("../pages/categorytags/CategoryTagList"),
);
const AddCategoryTag = lazy(() =>
  import("../pages/categorytags/AddCategoryTag"),
);
const EditCategoryTag = lazy(() =>
  import("../pages/categorytags/EditCategoryTag"),
);

const AppsAndDeals = lazy(() => import("../pages/apps_and_deals/AppsAndDeals"));

//FAQ
const EditFAQ = lazy(() => import("../pages/FAQ/EditFAQ"));
const AddFAQ = lazy(() => import("../pages/FAQ/AddFAQ"));
const AddFAQType = lazy(() => import("../pages/FAQ/AddFAQType"));
const AddTypeList = lazy(() => import("../pages/FAQ/QuestionTypeList"));
const EditType = lazy(() => import("../pages/FAQ/EditType"));
const FAQList = lazy(() => import("../pages/FAQ/FAQList"));

// notification
const EditNotification = lazy(() =>
  import("../pages/Notification/EditNotification"),
);
const AddNotification = lazy(() =>
  import("../pages/Notification/AddNotification"),
);
const NotificationList = lazy(() =>
  import("../pages/Notification/NotificationList"),
);

// notification
const EditCredit = lazy(() => import("../pages/credits/EditCredits"));
const AddCredit = lazy(() => import("../pages/credits/AddCredits"));
const CreditList = lazy(() => import("../pages/credits/CreditsList"));
const BlogList = lazy(() => import("../pages/blogs/BlogList"));
const AddBlog = lazy(() => import("../pages/blogs/AddBlog"));
const EditBlog = lazy(() => import("../pages/blogs/EditBlog"));

// Newsletter
const NewsletterList = lazy(() => import("../pages/newsletter/NewsLetterList"));

const routes = (
  <Routes>
    <Route
      index
      path="/login"
      element={
        <PublicRoute>
          <Login />
        </PublicRoute>
      }
    />
    <Route
      index
      path="/forgot-password"
      element={
        <PublicRoute>
          <ForgotPassword />
        </PublicRoute>
      }
    />
    <Route
      index
      path="/reset-password"
      element={
        <PublicRoute>
          <ResetPassword />
        </PublicRoute>
      }
    />
    <Route
      path="/"
      element={
        <RequireAuth>
          <MainLayout />
        </RequireAuth>
      }
    >
      <Route path="/" element={<Navigate to="users" replace />} />
      <Route
        path="users"
        element={
          <Suspense fallback={<LoadingPage />}>
            <UserList />
          </Suspense>
        }
      />
      <Route
        path="users/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddUser />
          </Suspense>
        }
      />
      <Route
        path="users/edit/:userId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditUser />
          </Suspense>
        }
      />
      <Route
        path="categories"
        element={
          <Suspense fallback={<LoadingPage />}>
            <CategoryList />
          </Suspense>
        }
      />
      <Route
        path="categories/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddCategory />
          </Suspense>
        }
      />
      <Route
        path="categories/edit/:categoryId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditCategory />
          </Suspense>
        }
      />
      <Route
        path="vat_categories"
        element={
          <Suspense fallback={<LoadingPage />}>
            <VatCategoryList />
          </Suspense>
        }
      />
      <Route
        path="vat_categories/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddVatCategory />
          </Suspense>
        }
      />
      <Route
        path="vat_categories/edit/:vatCategoryId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditVatCategory />
          </Suspense>
        }
      />
      <Route
        path="/subcategory"
        element={
          <Suspense fallback={<LoadingPage />}>
            <SubCategoryList />
          </Suspense>
        }
      />
      <Route
        path="/subcategory/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddSubCategory />
          </Suspense>
        }
      />
      <Route
        path="/subcategory/edit/:subCatId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditSubCategory />
          </Suspense>
        }
      />
      <Route
        path="/membership"
        element={
          <Suspense fallback={<LoadingPage />}>
            <MemberShipList />
          </Suspense>
        }
      />
      <Route
        path="/membership/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddMemberShip />
          </Suspense>
        }
      />
      <Route
        path="/membership/edit/:memberShipId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditMemberShip />
          </Suspense>
        }
      />
      <Route
        path="subscription/"
        element={
          <Suspense fallback={<LoadingPage />}>
            <SubScriptionList />
          </Suspense>
        }
      />
      <Route
        path="subscription/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddSubScription />
          </Suspense>
        }
      />
      <Route
        path="subscription/edit/:subScriptionId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditSubscription />
          </Suspense>
        }
      />
      <Route
        path="/FAQ"
        element={
          <Suspense fallback={<LoadingPage />}>
            <FAQList />
          </Suspense>
        }
      />
      <Route
        path="/FAQ/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddFAQ />
          </Suspense>
        }
      />
      <Route
        path="/FAQ/addType"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddFAQType />
          </Suspense>
        }
      />
      <Route
        path="/FAQ/typeList"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddTypeList />
          </Suspense>
        }
      />
      <Route
        path="/FAQ/type/edit/:typeId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditType />
          </Suspense>
        }
      />
      <Route
        path="/FAQ/edit/:FAQId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditFAQ />
          </Suspense>
        }
      />
      <Route
        path="/notification"
        element={
          <Suspense fallback={<LoadingPage />}>
            <NotificationList />
          </Suspense>
        }
      />
      <Route
        path="/notification/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddNotification />
          </Suspense>
        }
      />
      <Route
        path="/notification/edit/:notificationId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditNotification />
          </Suspense>
        }
      />
      <Route
        path="/credits"
        element={
          <Suspense fallback={<LoadingPage />}>
            <CreditList />
          </Suspense>
        }
      />
      <Route
        path="/credits/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddCredit />
          </Suspense>
        }
      />
      <Route
        path="/credits/edit/:creditId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditCredit />
          </Suspense>
        }
      />
      <Route
        path="/blogs"
        element={
          <Suspense fallback={<LoadingPage />}>
            <BlogList />
          </Suspense>
        }
      />
      <Route
        path="/blogs/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddBlog />
          </Suspense>
        }
      />
      <Route
        path="/blogs/edit/:blogId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditBlog />
          </Suspense>
        }
      />
      <Route
        path="categorytags"
        element={
          <Suspense fallback={<LoadingPage />}>
            <CategoryTagList />
          </Suspense>
        }
      />
      <Route
        path="categorytags/add"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AddCategoryTag />
          </Suspense>
        }
      />
      <Route
        path="categorytags/edit/:tagId"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditCategoryTag />
          </Suspense>
        }
      />
      <Route
        path="/apps_and_deals/*"
        element={
          <Suspense fallback={<LoadingPage />}>
            <AppsAndDeals />
          </Suspense>
        }
      />
      <Route
        path="/offers"
        element={
          <Suspense fallback={<LoadingPage />}>
            <OfferList />
          </Suspense>
        }
      />
      <Route
        path="/offer/:id"
        element={
          <Suspense fallback={<LoadingPage />}>
            <EditOffer />
          </Suspense>
        }
      />
      <Route
        index
        path="/newslettersList"
        element={
          <Suspense fallback={<LoadingPage />}>
            <NewsletterList />
          </Suspense>
        }
      />
    </Route>
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default routes;
