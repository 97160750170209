import React from "react";

import { Button, CircularProgress } from "@mui/material";

export default function CustomBtn({ children, loading, ...props }) {
  return (
    <Button
      {...props}
      disabled={!!loading}
      startIcon={
        loading ? <CircularProgress color="grey" size={20} sx={{ mr: 1}}/> : ""
      }
    >
      {children}
    </Button>
  );
}
