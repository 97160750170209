import React, { useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom';
import API from "../../config/axios";
import toast from "react-hot-toast";
import AccountOfferEdit from "./offerTypes/AccountOfferEdit";
import AdvocateOfferEdit from "./offerTypes/AdvocateOfferEdit";
import DataAnalistOfferEdit from "./offerTypes/DataAnalistOfferEdit";
import DeveloperOfferEdit from "./offerTypes/DeveloperOfferEdit";
import GraphicDesignerOfferEdit from "./offerTypes/GraphicDesignerOfferEdit";
import CareerCoachOfferEdit from "./offerTypes/CareerCoachOfferEdit";
import BusinessCoachOfferEdit from "./offerTypes/BusinessCoachOfferEdit";
import LifeCoachOfferEdit from "./offerTypes/LifeCoachOfferEdit";
import EmotionalCoachOfferEdit from "./offerTypes/EmotionalCoachOfferEdit";
import BudgetCoachCoachOfferEdit from "./offerTypes/BudgetCoachCoachOfferEdit";

function EditOffer() {
  const { id } = useParams();
  const [offer, setOffer] = useState({});
  const [offerType, setOfferType] = useState();

  const offerController = useRef(new AbortController());

  const getOfferData = async () => {
    const url = `/admin/offer/${id}`;
    API.get(url, {
      signal: offerController.current.signal,
      // params: query,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          setOffer(resData.data);
        } else {
          toast.error("Countries cannot be fetched");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something went wrong");
        }
      });
  };

  useEffect(() => {
    setOfferType(offer.offerType);
  }, [offer]);

  useEffect(() => {
    getOfferData();
    // eslint-disable-next-line
  }, []);

  return (
    <div>

      {offerType &&
        offer.offerType === "accountant" &&
        offerType === "accountant"
        ? <AccountOfferEdit offer={offer} />
        : ""}
      {offerType &&
        offer.offerType === "advocate" &&
        offerType === "advocate"
        ? <AdvocateOfferEdit offer={offer} />
        : ""}
      {offerType &&
        offer.offerType === "dataAnalist" &&
        offerType === "dataAnalist"
        ? <DataAnalistOfferEdit offer={offer} />
        : ""}
      {offerType &&
        offer.offerType === "developer" &&
        offerType === "developer"
        ? <DeveloperOfferEdit offer={offer} />
        : ""}
      {offerType &&
        offer.offerType === "graphicDesigner" &&
        offerType === "graphicDesigner"
        ? <GraphicDesignerOfferEdit offer={offer} />
        : ""}
      {offerType &&
        offer.offerType === "careerCoach" &&
        offerType === "careerCoach"
        ? <CareerCoachOfferEdit offer={offer} />
        : ""}
      {offerType &&
        offer.offerType === "businessCoach" &&
        offerType === "businessCoach"
        ? <BusinessCoachOfferEdit offer={offer} />
        : ""}
      {offerType &&
        offer.offerType === "lifeCoach" &&
        offerType === "lifeCoach"
        ? <LifeCoachOfferEdit offer={offer} />
        : ""}
      {offerType &&
        offer.offerType === "emotionalCoach" &&
        offerType === "emotionalCoach"
        ? <EmotionalCoachOfferEdit offer={offer} />
        : ""}
      {offerType &&
        offer.offerType === "budgetCoach" &&
        offerType === "budgetCoach"
        ? <BudgetCoachCoachOfferEdit offer={offer} />
        : ""}
    </div>
  );
}

export default EditOffer;
