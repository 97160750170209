import { TextField, Typography } from "@mui/material";
import { Autocomplete } from "@react-google-maps/api";
import React, { useState } from "react";
import { getLatitudeLongitude } from "../../helpers/helperFunctions";

function LocationPicker({
  control,
  customError,
  placeholder,
  setValue,
  inputName,
  CoordinatesTagName,
  ...otherProps
}) {

  const [searchResult, setSearchResult] = useState("");

  const onLoad = (autocomplete) => {
    setSearchResult(autocomplete);
  };

  const handlePlaceChanged = () => {
    const place = searchResult.getPlace();
    const companyAddressInputValue = place.formatted_address;
    setValue(inputName, companyAddressInputValue);
    getLatitudeLongitude(
      companyAddressInputValue,
      CoordinatesTagName,
      setValue
    );
  };

  return (
    <>
      <Typography className="labelName">{placeholder}</Typography>
      <Autocomplete onPlaceChanged={handlePlaceChanged} onLoad={onLoad}>
        <TextField
          fullWidth
          sx={{
            " & .MuiInputBase-input": {
              height: "10px",
              border: customError ? "1px solid #d32f2f" : "",
              borderRadius: customError ? "4px" : "",
            },
          }}
          placeholder={otherProps.defaultValue  ? otherProps.defaultValue:""}
          name={inputName}
          type="text"
          control={control}
          {...otherProps}
        />
      </Autocomplete>
      {customError ? <p className="errorMessage">{customError}</p> : ""}
    </>
  );
}

export default LocationPicker;
