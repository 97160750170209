import { TextareaAutosize } from "@mui/material";
import { useController } from "react-hook-form";

const TextareaAutoSize = ({ name, control, ...rest }) => {
  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
  });

  return (
    <TextareaAutosize
      {...inputProps}
      {...rest}
      inputRef={ref}
      style={{
        outlineColor: "#049bff",
        minWidth: 300,
        borderColor: "#cccccc",
        fontSize: "16px",
        padding: "10px",
        fontFamily: "inherit",
      }}
      minRows={3} // You can adjust this value as needed
    />
  );
};

export default TextareaAutoSize;
