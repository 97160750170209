import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import CountriesMenu from "../../../components/CountriesMenu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useForm } from "react-hook-form";
import AutocompleteInput from "../../../components/form/AutocompleteInput";
import TextareaAutoSize from "../../../components/form/TextareaAutoSize";
import TextInput from "../../../components/form/TextInput";
import CheckBox from "../../../components/form/CheckBox";
import toast from "react-hot-toast";
import API from "../../../config/axios";
import { highlightField } from "../../../utils/Commons";
import LocationPicker from "../../../components/form/LocationPicker";
import { getLatitudeLongitude } from "../../../helpers/helperFunctions";
import { useNavigate } from "react-router-dom";
import CustomDialogBox from "../../../components/CustomDialogBox";

const coachOfferValidationSchema = yup.object().shape({
  companyCountryId: yup.string().required(),
  companyName: yup.string().required(),
  companyOwnerName: yup.string().required(),
  companyEmail: yup.string().email().required(),
  companyWebsite: yup.string().required(),
  companyAddress: yup.string().required(),
  companyPostalCode: yup.number().required(),
  companyPhoneNo: yup.string().required().length(10),
  companyCountryCode: yup.string().required(),
  termsAndCondition: yup.boolean().required().oneOf([true]),
  companyLocation: yup.boolean(),
  companyEmailPreference: yup.boolean(),
  companyQuoteRequest: yup.boolean().required(),
  workSituation: yup.object().required(),
  gender: yup.object().required(),
  contactType: yup.object().required(),
  subCoachType: yup.object().required(),
  deadline: yup.object().required(),
  age: yup.number().required().min(1).max(125),
  explanation: yup.string().required(),
  companyAddressCoordinates: yup.object().shape({
    latitude: yup.number().required(),
    longitude: yup.number().required(),
  }),
  coachType: yup
    .object()
    .shape({
      value: yup.string().required().notOneOf([null, ""]),
    })
    .required(),
});

function LifeCoachOfferEdit({ offer }) {
  const { t } = useTranslation();
  const offerId = offer.id || offer._id;

  const auth = useAuth();
  const userId = auth?.user?.id;
  const countries = useSelector((state) => state.common.countries);

  const [inputErrors, setInputErrors] = useState({});
  const coachOfferController = useRef(new AbortController());

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [countryCode, setCountryCode] = useState("91");
  const [locationDefaultValue, setLocationDefaultValue] = useState("");
  const navigate = useNavigate();
  const [priceBox, setPriceBox] = useState(false);

  const coachType = [
    { name: t("offers.offer_create.coach.career_coach"), value: "careerCoach" },
    {
      name: t("offers.offer_create.coach.business_coach"),
      value: "businessCoach",
    },
    { name: t("offers.offer_create.coach.life_coach"), value: "lifeCoach" },
    {
      name: t("offers.offer_create.coach.emotional_coach"),
      value: "emotionalCoach",
    },
    { name: t("offers.offer_create.coach.budget_coach"), value: "budgetCoach" },
  ];

  const workSituation = [
    {
      name: t("offers.offer_create.coach.work_situation_salaried"),
      value: "salaried",
    },
    {
      name: t("offers.offer_create.coach.work_situation_entrepreneur"),
      value: "entrepreneur",
    },
    {
      name: t("offers.offer_create.coach.work_situation_looking_for_work"),
      value: "lookingForWork",
    },
    {
      name: t(
        "offers.offer_create.coach.work_situation_just_graduated_starter"
      ),
      value: "justGraduatedStarter",
    },
    {
      name: t("offers.offer_create.coach.work_situation_student"),
      value: "student",
    },
    {
      name: t("offers.offer_create.coach.work_situation_retired"),
      value: "retired",
    },
    {
      name: t("offers.offer_create.coach.work_situation_otherwise"),
      value: "otherwise",
    },
  ];
  const gender = [
    { name: t("offers.offer_create.coach.gender_male"), value: "male" },
    { name: t("offers.offer_create.coach.gender_female"), value: "female" },
  ];
  const contactType = [
    {
      name: t("offers.offer_create.coach.contact_personal_contact"),
      value: "personal",
    },
    {
      name: t("offers.offer_create.coach.contact_remote_contact"),
      value: "remote",
    },
    {
      name: t(
        "offers.offer_create.coach.contact_partly_person_partly_remotely"
      ),
      value: "partlyPersonAndRemotely",
    },
  ];
  const subCoachType = [
    {
      name: t("offers.offer_create.coach.coachsub_no_preference"),
      value: "noPreference",
    },
    {
      name: t("offers.offer_create.coach.coachsub_both_feet_and_ground"),
      value: "bothFeetAndGround",
    },
    {
      name: t("offers.offer_create.coach.coachsub_soft_matters"),
      value: "softMatters",
    },
  ];
  const deadLine = [
    { name: t("offers.offer_create.website.deadline_asap"), value: "asap" },
    {
      name: t("offers.offer_create.website.deadline_two_week"),
      value: "within2weeks",
    },
    {
      name: t("offers.offer_create.website.deadline_one_month"),
      value: "within1month",
    },
    {
      name: t("offers.offer_create.website.deadline_three_months"),
      value: "within3months",
    },
    {
      name: t("offers.offer_create.website.deadline_nohurry"),
      value: "noHurry",
    },
  ];

  // Default Values For Update coach Form
  const defaultValues = {
    userId: userId,
    addedByUserId: userId,
    updatedByUserId: userId,
    companyCountryId: null,

    coachType: null,
    workSituation: null,
    gender: null,
    contactType: null,
    subCoachType: null,
    deadline: null,
    age: null,
    explanation: "",
    companyAddressCoordinates: null,
    companyName: null,
    companyOwnerName: null,
    termsAndCondition: null,
    companyQuoteRequest: false,
    companyPhoneNo: null,
    companyCountryCode: null,
    companyEmail: null,
    companyWebsite: null,
    companyLocation: false,
    companyEmailPreference: false,
    companyAddress: null,
    companyPostalCode: null,
  };

  const { handleSubmit, control, setValue, getValues } = useForm({
    defaultValues: defaultValues,
    mode: "onBlur",
    resolver: yupResolver(coachOfferValidationSchema),
  });

  const onError = (errors) => {
    try {
      highlightField(Object.keys(errors)[0]);
    } catch (error) {
      console.warn("Field cannot be highlighted");
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const fillForm = () => {
    const optionalInputs = [
      "deadline",
      "coachType",
      "contactType",
      "gender",
      "workSituation",
      "subCoachType",
    ];

    const formKeys = Object.keys(offer).filter(
      (item) =>
        item !== "id" &&
        item !== "deleted" &&
        item !== "offerType" &&
        item !== "status" &&
        item !== "company"
    );
    formKeys.forEach((key) => {
      const value = offer[key];
      if (optionalInputs.includes(key)) {
        let newValue;
        switch (key) {
          case "coachType":
            newValue = coachType.find((item) => item.value === value);
            if (newValue) setValue(key, newValue);
            break;
          case "deadline":
            newValue = deadLine.find((item) => item.value === value);
            if (newValue) setValue(key, newValue);
            break;
          case "contactType":
            newValue = contactType.find((item) => item.value === value);
            if (newValue) setValue(key, newValue);
            break;
          case "gender":
            newValue = gender.find((item) => item.value === value);
            if (newValue) setValue(key, newValue);
            break;
          case "workSituation":
            newValue = workSituation.find((item) => item.value === value);
            if (newValue) setValue(key, newValue);
            break;
          case "subCoachType":
            newValue = subCoachType.find((item) => item.value === value);
            if (newValue) setValue(key, newValue);
            break;
          default:
            break;
        }
      }

      if (!optionalInputs.includes(key)) {
        setValue(key, value);
      }
    });

    // set company data
    Object.keys(offer.company).forEach((companyKey) => {
      const value = offer.company[companyKey];

      if (companyKey === "privacyGuarantee") {
        setValue("termsAndCondition", value);
      } else {
        const labelName = `company${
          companyKey.charAt(0).toUpperCase() + companyKey.slice(1)
        }`;
        setValue(labelName, value);
        if (labelName === "companyAddress") {
          setLocationDefaultValue(value);
          getLatitudeLongitude(value, "companyAddressCoordinates", setValue);
        }
        if (labelName === "companyCountryCode") {
          setCountryCode(value);
          setValue("companyCountryCode", countryCode);
          const country = countries.find(
            (country) => country.code === countryCode
          );
          if (country && country.id) {
            setValue("companyCountryId", country.id);
          }
        }
      }
    });
  };

  const rejectOffer = (offerId) => {
    const query = {
      status: "rejected",
    };

    API.put(`/admin/offer/${offerId}`, query, {
      signal: coachOfferController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          toast.success("coach Offer Updated");
          navigate("/offers");
        } else {
          toast.error("Something Went Wrong At Update coach Offer");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          console.log(error);
          toast.error(
            error?.response?.data?.error ||
              "Something Went Wrong At Update coach Offer"
          );
        }
      })
      .finally(() => {
        // setIsLoadingPeriods(false);
      });
  };

  const approveOffer = (offerId, credit) => {
    const query = {
      status: "approved",
      credit,
    };

    API.put(`/admin/offer/${offerId}`, query, {
      signal: coachOfferController.current.signal,
    })
      .then((res) => {
        const resData = res.data;
        if (resData.status) {
          toast.success("coach Offer Updated");
          setPriceBox(false);
          navigate("/offers");
        } else {
          toast.error("Something Went Wrong At Update coach Offer");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error(
            error?.response?.data?.error ||
              "Something Went Wrong At Update coach Offer"
          );
        }
      })
      .finally(() => {
        // setIsLoadingPeriods(false);
      });
  };
  const saveAction = () => {
    const credit = getValues("credit");
    if (credit && credit > 0) {
      approveOffer(offerId, credit);
    } else {
      setInputErrors({ credit: "credit is required field" });
    }
  };

  const deleteOffer = (offerId) => {
    coachOfferController.current.abort();
    coachOfferController.current = new AbortController();

    API.delete(`/admin/offer/${offerId}`)
      .then((res) => {
        const resData = res.data;
        if (resData.status) {
          // Return To Home Page
          navigate("/offers");
        } else {
          toast.error("Something Went Wrong At Delete Offer");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something Went Wrong At Delete Offer");
        }
      })
      .finally(() => {});
  };

  const updateCoachOffer = (formData) => {
    coachOfferController.current.abort();
    coachOfferController.current = new AbortController();

    API.put(`/admin/offer/${offerId}`, formData, {
      signal: coachOfferController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          toast.success("Coach Offer Update");
          navigate("/offers");
        } else {
          toast.error("Something Went Wrong At Update Coach Offer");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something Went Wrong At Update Coach Offer");
        }
      })
      .finally(() => {
        // setIsLoadingPeriods(false);
      });
  };

  useEffect(() => {
    setInputErrors({});
    fillForm();
    // eslint-disable-next-line
  }, []);

  // Set Country Code And Country Id
  useEffect(() => {
    if (countryCode && countryCode !== "") {
      setValue("companyCountryCode", countryCode);
      const country = countries.find((country) => country.code === countryCode);
      if (country && country.id) setValue("companyCountryId", country.id);
    }
    // eslint-disable-next-line
  }, [countryCode]);

  return (
    <div className="p-1">
      <form
        autoComplete="off"
        onSubmit={handleSubmit(updateCoachOffer, onError)}
      >
        <CustomDialogBox
          isOpen={priceBox}
          title={"Enter Credits"}
          onClose={() => {
            setPriceBox(false);
          }}
          children={
            <TextInput
              name="credit"
              type="number"
              label={"Credits"}
              control={control}
              customError={inputErrors.credit || null}
            />
          }
          actions={
            <Button
              type="submit"
              variant="contained"
              style={{ backgroundColor: "#0080FF" }}
              onClick={saveAction}
            >
              Save
            </Button>
          }
        />
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <AutocompleteInput
                name="coachType"
                readOnly={true}
                type="text"
                label={""}
                control={control}
                options={coachType}
                getOptionLabel={(option) => option.name}
                disableClearable={true}
                customError={
                  inputErrors.coachType?.value || inputErrors.coachType || null
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} p={2}>
            <Grid item xs={12}>
              <Grid item sm={6} xs={12} md={4}>
                <AutocompleteInput
                  name="workSituation"
                  type="text"
                  label={t("offers.offer_create.coach.label_work_situation")}
                  control={control}
                  options={workSituation}
                  getOptionLabel={(option) => option.name}
                  disableClearable={true}
                  customError={
                    inputErrors.workSituation?.value ||
                    inputErrors.workSituation ||
                    null
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item sm={6} xs={12} md={8}>
                <TextareaAutoSize
                  label={t("offers.offer_create.accountant.lable.explanation")}
                  type="text"
                  name="explanation"
                  control={control}
                  customError={inputErrors.explanation || null}
                  placeholder={t(
                    "offers.offer_create.coach.explanation_life_coach"
                  ).replace(/\/n/g, "\n")}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item sm={6} xs={12} md={4}>
                <AutocompleteInput
                  name="gender"
                  type="text"
                  label={t("offers.offer_create.coach.label_gender")}
                  control={control}
                  options={gender}
                  getOptionLabel={(option) => option.name}
                  disableClearable={true}
                  customError={
                    inputErrors.gender?.value || inputErrors.gender || null
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item sm={6} xs={12} md={4}>
                <TextInput
                  name="age"
                  type="text"
                  label={t("offers.offer_create.coach.label_age")}
                  autoFocus={true}
                  control={control}
                  customError={inputErrors.age || null}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item sm={6} xs={12} md={4}>
                <AutocompleteInput
                  name="subCoachType"
                  type="text"
                  label={t("offers.offer_create.coach.label_sub_coach_type")}
                  control={control}
                  options={subCoachType}
                  getOptionLabel={(option) => option.name}
                  disableClearable={true}
                  customError={
                    inputErrors.subCoachType?.value ||
                    inputErrors.subCoachType ||
                    null
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item sm={6} xs={12} md={4}>
                <AutocompleteInput
                  name="deadline"
                  type="text"
                  label={t("offers.offer_create.website.deadline")}
                  control={control}
                  options={deadLine}
                  getOptionLabel={(option) => option.name}
                  disableClearable={true}
                  customError={
                    inputErrors.deadline?.value || inputErrors.deadline || null
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item sm={6} xs={12} md={4}>
                <AutocompleteInput
                  name="contactType"
                  type="text"
                  label={t("offers.offer_create.coach.label_contact_type")}
                  control={control}
                  options={contactType}
                  getOptionLabel={(option) => option.name}
                  disableClearable={true}
                  customError={
                    inputErrors.contactType?.value ||
                    inputErrors.contactType ||
                    null
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Typography style={{ color: "#d18615" }} fontSize="20px">
              {t(`offers.offer_create.website.contact_details`)}
            </Typography>
            <Typography color="black" fontSize="20px">
              {t(`offers.offer_create.website.contact_details_will_private`)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <TextInput
                name="companyOwnerName"
                type="text"
                label={t("offers.offer_create.website.your_name")}
                autoFocus={true}
                control={control}
                customError={inputErrors.companyOwnerName || null}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <TextInput
                name="companyName"
                type="text"
                label={t("offers.offer_create.website.comapny_name")}
                autoFocus={true}
                control={control}
                customError={inputErrors.companyName || null}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Grid item sm={6} xs={12} md={4}>
                <TextInput
                  name="companyPostalCode"
                  type="text"
                  label={t("offers.offer_create.website.post_code")}
                  autoFocus={true}
                  control={control}
                  customError={inputErrors.companyPostalCode || null}
                />
              </Grid>
              <Grid item sm={6} xs={12} md={4} sx={{ alignSelf: "flex-end" }}>
                <CheckBox
                  label={t(
                    "offers.offer_create.website.located_outside_netherland"
                  )}
                  control={control}
                  name="companyLocation"
                  color="info"
                  inputProps={{ "aria-label": "controlled" }}
                  customError={inputErrors.companyLocation || null}
                />
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <TextInput
                name="companyEmail"
                type="email"
                label={t("offers.offer_create.website.email")}
                autoFocus={true}
                control={control}
                customError={inputErrors.companyEmail || null}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <TextInput
                name="companyWebsite"
                type="text"
                label={t("offers.offer_create.website.website")}
                autoFocus={true}
                control={control}
                customError={inputErrors.companyWebsite || null}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Grid item sm={6} xs={12} md={4}>
                <div>
                  <TextInput
                    name="companyPhoneNo"
                    label={t("offers.offer_create.website.mobile_number")}
                    control={control}
                    customError={inputErrors.companyPhoneNo || null}
                    fullWidth
                    onChangeCallback={(inputValue) => {
                      const newValue = inputValue
                        .replace(/^0+/, "")
                        .replace(/[^0-9]/g, "");
                      setValue("companyPhoneNo", newValue);
                    }}
                    InputProps={{
                      startAdornment: (
                        <Box
                          style={{
                            marginRight: "5px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={handleClick}
                        >
                          +{countryCode}
                          <KeyboardArrowDownIcon
                            sx={{ color: "grey", fontSize: 16 }}
                          />
                        </Box>
                      ),
                    }}
                  />
                  <CountriesMenu
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    open={open}
                    setOpen={setOpen}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                  />
                </div>
              </Grid>
              <Grid item sm={6} xs={12} md={6} sx={{ alignSelf: "flex-end" }}>
                <CheckBox
                  label={t("offers.offer_create.website.email_to_receive")}
                  control={control}
                  name="companyEmailPreference"
                  color="info"
                  inputProps={{ "aria-label": "controlled" }}
                  customError={inputErrors.companyEmailPreference || null}
                />
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <LocationPicker
                control={control}
                placeholder={t("offers.offer_create.website.company_address")}
                customError={inputErrors.companyAddress || null}
                setValue={setValue}
                inputName={"companyAddress"}
                CoordinatesTagName={"companyAddressCoordinates"}
                defaultValue={locationDefaultValue}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ paddingLeft: "0px !important" }}>
            <Grid item sm={6} xs={12} md={4}>
              <CheckBox
                label={t("offers.offer_create.website.company_quote_request")}
                control={control}
                name="companyQuoteRequest"
                color="info"
                inputProps={{ "aria-label": "controlled" }}
                customError={inputErrors.companyQuoteRequest || null}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              paddingLeft: "0px !important",
              paddingTop: "0px !important",
            }}
          >
            <Box sx={{ width: "max-content" }}>
              <CheckBox
                label={t("offers.offer_create.website.accept_condition")}
                control={control}
                name="termsAndCondition"
                color="info"
                inputProps={{ "aria-label": "controlled" }}
                customError={inputErrors.termsAndCondition || null}
              />
            </Box>
          </Grid>
          {offer && offer.status === "pending" ? (
            <div>
              <Button
                variant="outlined"
                color="success"
                style={{ marginLeft: "1rem" }}
                onClick={() => setPriceBox(true)}
              >
                Approve Offer
              </Button>
              <Button
                variant="outlined"
                color="warning"
                style={{ marginLeft: "1rem" }}
                onClick={() => rejectOffer(offerId)}
              >
                Reject Offer
              </Button>
            </div>
          ) : offer && offer.status === "rejected" ? (
            <div>
              <Button
                variant="outlined"
                color="warning"
                style={{ marginLeft: "1rem" }}
                onClick={() => deleteOffer(offerId)}
              >
                Delete Offer
              </Button>
            </div>
          ) : (
            ""
          )}

          <Grid item xs={12}>
            <Box sx={{ width: "max-content" }}>
              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: "#0080FF" }}
              >
                Update Offer
                <img
                  alt=""
                  src={"./nextWhite.png"}
                  style={{ marginLeft: "10px" }}
                />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default LifeCoachOfferEdit;
