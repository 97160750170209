import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translations/en.json";
import nl from "./translations/nl.json";

i18n.use(initReactI18next).init({
  lng: "nl",
  fallbackLng: "nl",
  resources: {
    en: {
      translation: en, // English translations
    },
    nl: {
      translation: nl, // Dutch translations
    },
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
