import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import CountriesMenu from "../../../components/CountriesMenu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useForm } from "react-hook-form";
import AutocompleteInput from "../../../components/form/AutocompleteInput";
import TextareaAutoSize from "../../../components/form/TextareaAutoSize";
import TextInput from "../../../components/form/TextInput";
import CheckBox from "../../../components/form/CheckBox";
import toast from "react-hot-toast";
import API from "../../../config/axios";
import { highlightField } from "../../../utils/Commons";
import LocationPicker from "../../../components/form/LocationPicker";
import { getLatitudeLongitude } from "../../../helpers/helperFunctions";
import { useNavigate } from "react-router-dom";
import CustomDialogBox from "../../../components/CustomDialogBox";

// Form Validation Schema
const GraphicDesignerOfferValidationSchema = yup.object().shape({
  companyCountryId: yup
    .string()
    .required("Company Country Id is required Field"),
  designKind: yup
    .array()
    .of(yup.object())
    .min(1, "Work Type Field must have at least one work"),
  explanation: yup.string().required(),
  companyAddressCoordinates: yup.object().shape({
    latitude: yup.number().required(),
    longitude: yup.number().required(),
  }),
  companyName: yup.string().required("Company Name is required Field"),
  companyOwnerName: yup.string().required("Owner Name is required Field"),
  companyEmail: yup
    .string()
    .email("Please Enter Valid Email Id")
    .required("Company Email is required Field"),
  companyWebsite: yup.string().required("Company Website is required Field"),
  companyAddress: yup.string().required("Company Address is required Field"),
  companyPostalCode: yup
    .string()
    .required("Company PostalCode is required Field"),
  deadline: yup.object().required("Deadline is required Field"),
  workMode: yup
    .object()
    .shape({
      value: yup
        .string()
        .required("Value is required Field")
        .notOneOf([null, ""], "Value cannot be null or empty"),
    })
    .required("Work Mode is required Field"),
  companyPhoneNo: yup
    .string()
    .required("Company PhoneNo is required Field")
    .length(10, "Phone number must be exactly 10 digits"),
  companyCountryCode: yup
    .string()
    .required("Company Country Code is required Field"),
  termsAndCondition: yup
    .boolean()
    .required("Terms and conditions must be accepted")
    .oneOf([true], "Terms and conditions must be accepted"),
  companyLocation: yup.boolean(),
  companyEmailPreference: yup.boolean(),
  companyQuoteRequest: yup
    .boolean()
    .required("Company Quote Request is required Field"),
});

function GraphicDesignerOfferEdit({ offer }) {
  const { t } = useTranslation();
  const offerId = offer.id || offer._id;

  const auth = useAuth();
  const userId = auth?.user?.id;
  const countries = useSelector((state) => state.common.countries);

  const [inputErrors, setInputErrors] = useState({});
  const graphicDesignerOfferController = useRef(new AbortController());

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [countryCode, setCountryCode] = useState("91");
  const [locationDefaultValue, setLocationDefaultValue] = useState("");
  const navigate = useNavigate();
  const [priceBox, setPriceBox] = useState(false);

  // Design Type Options
  const designKind = [
    {
      name: t("offers.offer_create.graphic_designer.logo_design"),
      value: "logoDesign",
    },
    {
      name: t("offers.offer_create.graphic_designer.corporate_identity_design"),
      value: "corporateIdentityDesign",
    },
    {
      name: t("offers.offer_create.graphic_designer.website_design"),
      value: "websiteDesign",
    },
    {
      name: t(
        "offers.offer_create.graphic_designer.animations_or_illustration"
      ),
      value: "animationsOrIllustration",
    },
    {
      name: t("offers.offer_create.graphic_designer.preparing_printed_matter"),
      value: "printedMatter",
    },
    { name: t("offers.offer_create.graphic_designer.other"), value: "other" },
  ];

  // Work Mode Options
  const workMode = [
    { name: t("offers.offer_create.website.work_remotely"), value: "remotely" },
    {
      name: t("offers.offer_create.website.work_remotely_and_personally"),
      value: "hybrid",
    },
  ];

  // Deadlines Options
  const deadLine = [
    { name: t("offers.offer_create.website.deadline_asap"), value: "asap" },
    {
      name: t("offers.offer_create.website.deadline_two_week"),
      value: "within2weeks",
    },
    {
      name: t("offers.offer_create.website.deadline_one_month"),
      value: "within1month",
    },
    {
      name: t("offers.offer_create.website.deadline_three_months"),
      value: "within3months",
    },
    {
      name: t("offers.offer_create.website.deadline_nohurry"),
      value: "noHurry",
    },
  ];

  // Default Values For Create Graphic Designer Form
  const defaultValues = {
    userId: userId,
    addedByUserId: userId,
    updatedByUserId: userId,
    companyCountryId: null,

    designKind: [],
    workMode: null,
    deadline: null,
    explanation: "",
    companyAddressCoordinates: null,
    companyName: null,
    companyOwnerName: null,
    termsAndCondition: null,
    companyQuoteRequest: false,
    companyPhoneNo: null,
    companyCountryCode: null,
    companyEmail: null,
    companyWebsite: null,
    companyLocation: false,
    companyEmailPreference: false,
    companyAddress: null,
    companyPostalCode: null,
  };

  const rejectOffer = (offerId) => {
    const query = {
      status: "rejected",
    };

    API.put(`/admin/offer/${offerId}`, query, {
      signal: graphicDesignerOfferController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          toast.success("Graphic Designer Offer Updated");
          navigate("/offers");
        } else {
          toast.error("Something Went Wrong At Update Graphic Designer Offer");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          console.log(error);
          toast.error(
            error?.response?.data?.error ||
              "Something Went Wrong At Update Graphic Designer Offer"
          );
        }
      })
      .finally(() => {
        // setIsLoadingPeriods(false);
      });
  };

  const approveOffer = (offerId, credit) => {
    const query = {
      status: "approved",
      credit,
    };

    API.put(`/admin/offer/${offerId}`, query, {
      signal: graphicDesignerOfferController.current.signal,
    })
      .then((res) => {
        const resData = res.data;
        if (resData.status) {
          toast.success("Graphic Designer Offer Updated");
          setPriceBox(false);
          navigate("/offers");
        } else {
          toast.error("Something Went Wrong At Update Graphic Designer Offer");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error(
            error?.response?.data?.error ||
              "Something Went Wrong At Update Graphic Designer Offer"
          );
        }
      })
      .finally(() => {
        // setIsLoadingPeriods(false);
      });
  };
  const saveAction = () => {
    const credit = getValues("credit");
    if (credit && credit > 0) {
      approveOffer(offerId, credit);
    } else {
      setInputErrors({ credit: "credit is required field" });
    }
  };

  const deleteOffer = (offerId) => {
    graphicDesignerOfferController.current.abort();
    graphicDesignerOfferController.current = new AbortController();

    API.delete(`/admin/offer/${offerId}`)
      .then((res) => {
        const resData = res.data;
        if (resData.status) {
          // Return To Home Page
          navigate("/offers");
        } else {
          toast.error("Something Went Wrong At Delete Offer");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something Went Wrong At Delete Offer");
        }
      })
      .finally(() => {});
  };

  const fillForm = () => {
    const optionalInputs = ["deadline", "workMode"];
    const multiple = ["designKind"];

    const formKeys = Object.keys(offer).filter(
      (item) =>
        item !== "id" &&
        item !== "deleted" &&
        item !== "offerType" &&
        item !== "status" &&
        item !== "company"
    );
    formKeys.forEach((key) => {
      const value = offer[key];
      if (optionalInputs.includes(key)) {
        let newValue;
        switch (key) {
          case "deadline":
            newValue = deadLine.find((item) => item.value === value);
            if (newValue) setValue(key, newValue);
            break;
          case "workMode":
            newValue = workMode.find((item) => item.value === value);
            if (newValue) setValue(key, newValue);
            break;
          default:
            break;
        }
      }
      if (multiple.includes(key)) {
        switch (key) {
          case "designKind":
            const newValues = designKind.filter((item) =>
              value.includes(item.value)
            );
            if (newValues) setValue(key, newValues);
            break;
          default:
            break;
        }
      }
      if (!multiple.includes(key) && !optionalInputs.includes(key)) {
        setValue(key, value);
      }
    });

    // set company data
    Object.keys(offer.company).forEach((companyKey) => {
      const value = offer.company[companyKey];

      if (companyKey === "privacyGuarantee") {
        setValue("termsAndCondition", value);
      } else {
        const labelName = `company${
          companyKey.charAt(0).toUpperCase() + companyKey.slice(1)
        }`;
        setValue(labelName, value);
        if (labelName === "companyAddress") {
          setLocationDefaultValue(value);
          getLatitudeLongitude(value, "companyAddressCoordinates", setValue);
        }
        if (labelName === "companyCountryCode") {
          setCountryCode(value);
          setValue("companyCountryCode", countryCode);
          const country = countries.find(
            (country) => country.code === countryCode
          );
          if (country && country.id) {
            setValue("companyCountryId", country.id);
          }
        }
      }
    });
  };

  // Set Form Default Values
  const { handleSubmit, control, setValue, getValues } = useForm({
    defaultValues: defaultValues,
    mode: "onBlur",
    resolver: yupResolver(GraphicDesignerOfferValidationSchema),
  });

  // Error Handler (Validation)
  const onError = (errors) => {
    console.log(errors);
    try {
      highlightField(Object.keys(errors)[0]);
    } catch (error) {
      console.log(error);
      console.warn("Field cannot be highlighted");
    }
  };

  const updateGraphicDesignerOffer = (formData) => {
    graphicDesignerOfferController.current.abort();
    graphicDesignerOfferController.current = new AbortController();

    API.put(`/admin/offer/${offerId}`, formData, {
      signal: graphicDesignerOfferController.current.signal,
    })
      .then((res) => {
        const resData = res.data;

        if (resData.status) {
          toast.success("Graphic Designer Offer Update");
          navigate("/offers");
        } else {
          toast.error("Something Went Wrong At Update Graphic Designer Offer");
        }
      })
      .catch((error) => {
        if (!error.code || error.code !== "ERR_CANCELED") {
          toast.error("Something Went Wrong At Update Graphic Designer Offer");
        }
      })
      .finally(() => {
        // setIsLoadingPeriods(false);
      });
  };

  // On Click Event For Phone And Country Select
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  useEffect(() => {
    setInputErrors({});
    fillForm();
    // eslint-disable-next-line
  }, []);

  // Set Country Code And Country Id
  useEffect(() => {
    if (countryCode && countryCode !== "") {
      setValue("companyCountryCode", countryCode);
      const country = countries.find((country) => country.code === countryCode);
      if (country && country.id) setValue("companyCountryId", country.id);
    }
    // eslint-disable-next-line
  }, [countryCode]);

  return (
    <div className="p-1">
      <form
        autoComplete="off"
        onSubmit={handleSubmit(updateGraphicDesignerOffer, onError)}
      >
        <CustomDialogBox
          isOpen={priceBox}
          title={"Enter Credits"}
          onClose={() => {
            setPriceBox(false);
          }}
          children={
            <TextInput
              name="credit"
              type="number"
              label={"Credits"}
              control={control}
              customError={inputErrors.credit || null}
            />
          }
          actions={
            <Button
              type="submit"
              variant="contained"
              style={{ backgroundColor: "#0080FF" }}
              onClick={saveAction}
            >
              Save
            </Button>
          }
        />
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <AutocompleteInput
                multiple={true}
                name="designKind"
                id="designKind"
                label={t("offers.offer_create.graphic_designer.kind_of_design")}
                type="text"
                control={control}
                options={designKind}
                getOptionLabel={(option) => option.name}
                loading={false}
                customError={inputErrors.designKind || null}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={8}>
              <TextareaAutoSize
                label={t("offers.offer_create.accountant.lable.explanation")}
                type="text"
                name="explanation"
                control={control}
                customError={inputErrors.explanation || null}
                placeholder={
                  t("offers.offer_create.accountant.explanation_two") +
                  "\n" +
                  t("offers.offer_create.accountant.explanation_one")
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <AutocompleteInput
                name="workMode"
                type="text"
                label={t(
                  "offers.offer_create.accountant.lable.desired_location"
                )}
                control={control}
                options={workMode}
                getOptionLabel={(option) => option?.name}
                isOptionEqualToValue={(option, value) => {
                  return option.value === value.value;
                }}
                customError={inputErrors?.workMode || null}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <AutocompleteInput
                name="deadline"
                type="text"
                label={t("offers.offer_create.website.deadline")}
                control={control}
                options={deadLine}
                getOptionLabel={(option) => option.name}
                disableClearable={true}
                customError={
                  inputErrors.deadline?.value || inputErrors.deadline || null
                }
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography style={{ color: "#d18615" }} fontSize="20px">
              {t(`offers.offer_create.website.contact_details`)}
            </Typography>
            <Typography color="black" fontSize="20px">
              {t(`offers.offer_create.website.contact_details_will_private`)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <TextInput
                name="companyOwnerName"
                type="text"
                label={t("offers.offer_create.website.your_name")}
                autoFocus={true}
                control={control}
                customError={inputErrors.companyOwnerName || null}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <TextInput
                name="companyName"
                type="text"
                label={t("offers.offer_create.website.comapny_name")}
                autoFocus={true}
                control={control}
                customError={inputErrors.companyName || null}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Grid item sm={6} xs={12} md={4}>
                <TextInput
                  name="companyPostalCode"
                  type="text"
                  label={t("offers.offer_create.website.post_code")}
                  autoFocus={true}
                  control={control}
                  customError={inputErrors.companyPostalCode || null}
                />
              </Grid>
              <Grid item sm={6} xs={12} md={4} sx={{ alignSelf: "flex-end" }}>
                <CheckBox
                  label={t(
                    "offers.offer_create.website.located_outside_netherland"
                  )}
                  control={control}
                  name="companyLocation"
                  color="info"
                  inputProps={{ "aria-label": "controlled" }}
                  customError={inputErrors.companyLocation || null}
                />
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <TextInput
                name="companyEmail"
                type="email"
                label={t("offers.offer_create.website.email")}
                autoFocus={true}
                control={control}
                customError={inputErrors.companyEmail || null}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <TextInput
                name="companyWebsite"
                type="text"
                label={t("offers.offer_create.website.website")}
                autoFocus={true}
                control={control}
                customError={inputErrors.companyName || null}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Grid item sm={6} xs={12} md={4}>
                <div>
                  <TextInput
                    name="companyPhoneNo"
                    label={t("offers.offer_create.website.mobile_number")}
                    control={control}
                    customError={inputErrors.companyPhoneNo || null}
                    fullWidth
                    onChangeCallback={(inputValue) => {
                      const newValue = inputValue
                        .replace(/^0+/, "")
                        .replace(/[^0-9]/g, "");
                      setValue("companyPhoneNo", newValue);
                    }}
                    InputProps={{
                      startAdornment: (
                        <Box
                          style={{
                            marginRight: "5px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={handleClick}
                        >
                          +{countryCode}
                          <KeyboardArrowDownIcon
                            sx={{ color: "grey", fontSize: 16 }}
                          />
                        </Box>
                      ),
                    }}
                  />
                  <CountriesMenu
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    open={open}
                    setOpen={setOpen}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                  />
                </div>
              </Grid>
              <Grid item sm={6} xs={12} md={6} sx={{ alignSelf: "flex-end" }}>
                <CheckBox
                  label={t("offers.offer_create.website.email_to_receive")}
                  control={control}
                  name="companyEmailPreference"
                  color="info"
                  inputProps={{ "aria-label": "controlled" }}
                  customError={inputErrors.companyEmailPreference || null}
                />
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12} md={4}>
              <LocationPicker
                control={control}
                placeholder={t("offers.offer_create.website.company_address")}
                customError={inputErrors.companyAddress || null}
                setValue={setValue}
                inputName={"companyAddress"}
                CoordinatesTagName={"companyAddressCoordinates"}
                defaultValue={locationDefaultValue}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ paddingLeft: "0px !important" }}>
            <Grid item sm={6} xs={12} md={4}>
              <CheckBox
                label={t("offers.offer_create.website.company_quote_request")}
                control={control}
                name="companyQuoteRequest"
                color="info"
                inputProps={{ "aria-label": "controlled" }}
                customError={inputErrors.companyQuoteRequest || null}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ paddingLeft: "0px !important", paddingTop: "0px !important" }}
          >
            <Box sx={{ width: "max-content" }}>
              <CheckBox
                label={t("offers.offer_create.website.accept_condition")}
                control={control}
                name="termsAndCondition"
                color="info"
                inputProps={{ "aria-label": "controlled" }}
                customError={inputErrors.termsAndCondition || null}
              />
            </Box>
          </Grid>

          {offer && offer.status === "pending" ? (
            <div>
              <Button
                variant="outlined"
                color="success"
                style={{ marginLeft: "1rem" }}
                onClick={() => setPriceBox(true)}
              >
                Approve Offer
              </Button>
              <Button
                variant="outlined"
                color="warning"
                style={{ marginLeft: "1rem" }}
                onClick={() => rejectOffer(offerId)}
              >
                Reject Offer
              </Button>
            </div>
          ) : offer && offer.status === "rejected" ? (
            <div>
              <Button
                variant="outlined"
                color="warning"
                style={{ marginLeft: "1rem" }}
                onClick={() => deleteOffer(offerId)}
              >
                Delete Offer
              </Button>
            </div>
          ) : (
            ""
          )}

          <Grid item xs={12}>
            <Box sx={{ width: "max-content" }}>
              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: "#0080FF" }}
              >
                Update Offer
                <img
                  alt=""
                  src={"./nextWhite.png"}
                  style={{ marginLeft: "10px" }}
                />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default GraphicDesignerOfferEdit;
