import { Controller } from "react-hook-form";
import { Checkbox, Typography } from "@mui/material";

const CheckBox = ({
  name,
  control,
  label,
  defaultValue,
  rules = { required: false },
  customError = null,
  ...otherProps
}) => {
  return (
    <>
      <Typography sx={{ mb: 1 }} variant="body2"></Typography>
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue} // Set the default value
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <>
              <Checkbox
                id={name}
                variant="outlined"
                color="info"
                size="large"
                value={value}
                checked={value}
                onChange={onChange}
                {...otherProps}
              />
              <Typography component="span" variant="body2">
                {label}
              </Typography>
            </>
          );
        }}
      />
    </>
  );
};

export default CheckBox;
