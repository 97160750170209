import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { useNavigate, useLocation } from "react-router-dom";

export default function SideBarItem({ item, classes = "" }) {
  let navigate = useNavigate();
  const location = useLocation();

  let isActive = false;

  for (let i = 0; i < item.activeOn.length; i++) {
    const route = item.activeOn[i];
    const regex = new RegExp(route);

    if (location.pathname.match(regex)) {
      isActive = true;
    }
  }

  const handleNavigation = (selectedModule) => {
    navigate(selectedModule.route);
  };

  return (
    <ListItemButton
      key={item.route}
      onClick={() => handleNavigation(item)}
      className={`side-nav-link ${isActive && "active"} ${classes}`}
    >
      <ListItemText
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
        }}
      >
        <Stack direction="row" spacing={2}>
          {item.icon}
          <Typography
            variant="body1"
            noWrap
            sx={{ fontSize: "14px" }}
            color="primaryText.dark"
          >
            {item.label}
          </Typography>
        </Stack>
      </ListItemText>
    </ListItemButton>
  );
}
